import { buildCdnImageUrl } from 'services/url';

type UseFooterReturn = {
  copyrightText: string;
  socialButtons: {
    name: string;
    iconUrl: string;
    url: string;
  }[];
};

export function useFooter(): UseFooterReturn {
  return {
    copyrightText: `© ${new Date().getFullYear() ?? 2022} ScheduleLab, LLC`,
    socialButtons: [
      {
        name: 'Facebook',
        iconUrl: buildCdnImageUrl('/social/facebook.svg'),
        url: 'https://www.facebook.com/ScheduleLabIO',
      },
      {
        name: 'Twitter',
        iconUrl: buildCdnImageUrl('/social/twitter.svg'),
        url: 'https://twitter.com/ScheduleLabIO',
      },
      {
        name: 'Instagram',
        iconUrl: buildCdnImageUrl('/social/instagram.svg'),
        url: 'https://www.instagram.com/ScheduleLabIO',
      },
      {
        name: 'TikTok',
        iconUrl: buildCdnImageUrl('/social/tiktok.svg'),
        url: 'https://www.tiktok.com/@ScheduleLab',
      },
      {
        name: 'Discord',
        iconUrl: buildCdnImageUrl('/social/discord.svg'),
        url: 'https://discord.gg/VKr3FnpNdW',
      },
      {
        name: 'Reddit',
        iconUrl: buildCdnImageUrl('/social/reddit.svg'),
        url: 'https://www.reddit.com/r/schedulelab',
      },
      {
        name: 'YouTube',
        iconUrl: buildCdnImageUrl('/social/youtube.svg'),
        url: 'https://www.youtube.com/channel/UC4gSZLLdG8OaUwaLd8baX0Q',
      },
      {
        name: 'Twitch',
        iconUrl: buildCdnImageUrl('/social/twitch.svg'),
        url: 'https://www.twitch.tv/schedulelab',
      },
      {
        name: 'LinkedIn',
        iconUrl: buildCdnImageUrl('/social/linkedin.svg'),
        url: 'https://www.linkedin.com/company/ScheduleLab-LLC',
      },
    ],
  };
}
