export type UseNavigationBarReturn = {
  pageLinks: { name: string; url: string }[];
};

export function useNavigationBar(): UseNavigationBarReturn {
  return {
    pageLinks: [
      { name: 'Home', url: '/' },
      { name: 'About Us', url: '/about' },
      { name: 'FAQ', url: '/faq' },
    ],
  };
}
