// extracted by mini-css-extract-plugin
export var appear = "bubbleVisual-module--appear---zGjY";
export var bubble = "bubbleVisual-module--bubble--TSem+";
export var bubbleRise = "bubbleVisual-module--bubble-rise--6Jpj8";
export var bubbleRiseContainer = "bubbleVisual-module--bubble-rise-container--8bzGA";
export var bubbleWiggle = "bubbleVisual-module--bubble-wiggle--86VCD";
export var bubbleWiggleContainer = "bubbleVisual-module--bubble-wiggle-container--wnPB-";
export var container = "bubbleVisual-module--container--ygsqD";
export var content = "bubbleVisual-module--content--E0zhA";
export var floating = "bubbleVisual-module--floating--Qbpg0";
export var rounded = "bubbleVisual-module--rounded--VorQM";
export var roundedCorners = "bubbleVisual-module--rounded-corners--zqmCu";
export var subbubble = "bubbleVisual-module--subbubble--peHUu";
export var subbubbleRise = "bubbleVisual-module--subbubble-rise--sniEm";
export var subbubbleRiseContainer = "bubbleVisual-module--subbubble-rise-container--eW5v1";
export var subbubbleRotateContainer = "bubbleVisual-module--subbubble-rotate-container--hRArj";