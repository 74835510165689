import * as componentClasses from '@schedulelab/components/bubbleVisual/bubbleVisual.module.scss';
import ComponentBounds from '@schedulelab/types/componentBounds';
import React, { PropsWithChildren, useState } from 'react';

type BubbleVisualProps = {
  width?: string;
  height?: string;
  bubbleCount?: number;
};

type BubbleState = {
  vwFromLeft: number;
  vhFromTop: number;
  sizeInVmax: number;
  opacity: number;
  wiggleDuration: number;
  riseDuration: number;
  reversedWiggle: boolean;
};

const DEFAULT_BUBBLE_COUNT = 40;
const MIN_BUBBLE_X = -20;
const MAX_BUBBLE_X = 100;
const MIN_BUBBLE_Y = 100;
const MAX_BUBBLE_Y = 200;
const INITIAL_MIN_BUBBLE_Y = 0;
const MIN_BUBBLE_SIZE = 1;
const MAX_BUBBLE_SIZE = 20;
const MIN_BUBBLE_OPACITY = 0.75;
const MAX_BUBBLE_OPACITY = 1;
const MIN_BUBBLE_DURATION = 4;
const MAX_BUBBLE_DURATION = 10;
const DELTA_BUBBLE_X = MAX_BUBBLE_X - MIN_BUBBLE_X;
const DELTA_BUBBLE_SIZE = MAX_BUBBLE_SIZE - MIN_BUBBLE_SIZE;
const DELTA_BUBBLE_OPACITY = MAX_BUBBLE_OPACITY - MIN_BUBBLE_OPACITY;
const DELTA_BUBBLE_DURATION = MAX_BUBBLE_DURATION - MIN_BUBBLE_DURATION;

// BubbleVisual is the main component, which contains all bubbles.
// Most of the logic is handled via Bubble, so it's relatively simple.
export const BubbleVisual: React.FC<PropsWithChildren<BubbleVisualProps>> = (props) => {
  const { width, height } = props;
  const bubbleCount: number = props.bubbleCount ?? DEFAULT_BUBBLE_COUNT;
  const dimensions = new ComponentBounds();
  dimensions.width = width;
  dimensions.height = height;
  const style = dimensions.getComputedStyle(true, true, false);
  // Create our bubbles :)
  const bubbles: React.ReactElement[] = [];
  for (let i = 0; i < bubbleCount; i++) {
    const bubbleKey: string = 'bubble-visual-' + bubbleCount + '-' + i;
    bubbles.push(<Bubble key={bubbleKey} />);
  }
  return (
    <div className={componentClasses.container} style={style}>
      <section className={componentClasses.content}>{props.children}</section>
      {bubbles}
    </div>
  );
};

// Bubble is an internal component of BubbleVisual.
// It manages it's own randomized size, position, duration, etc. via state.
// On animation end, it resets and randomizes its position/attributes.
const Bubble: React.FC = () => {
  const initialState: BubbleState = createRandomBubbleState(true);
  const [state, setState] = useState(initialState);
  // Define style based on state values by appending units
  const sizeWithUnits: string = state.sizeInVmax + 'vmax';
  const wiggleDirection: string = state.reversedWiggle ? 'alternate' : 'alternate-reverse';
  const wiggleContainerStyle: React.CSSProperties = {
    width: sizeWithUnits,
    height: sizeWithUnits,
    minWidth: sizeWithUnits,
    minHeight: sizeWithUnits,
    maxWidth: sizeWithUnits,
    maxHeight: sizeWithUnits,
    animationDuration: state.wiggleDuration + 's',
    animationDirection: wiggleDirection,
    opacity: state.opacity,
    left: state.vwFromLeft + 'vw',
    top: state.vhFromTop + 'vh',
  };
  const riseContainerStyle: React.CSSProperties = {
    animationDuration: state.riseDuration + 's',
  };
  const animationIterationHandler: React.DOMAttributes<never>['onAnimationIteration'] = () => {
    const newState: BubbleState = createRandomBubbleState(false);
    // Randomizing duration/direction causes visual bugs, so we do not change them.
    // There are potential workarounds, but would require additional work.
    // Relevant StackOverflow: https://stackoverflow.com/a/51562392
    newState.wiggleDuration = state.wiggleDuration;
    newState.riseDuration = state.riseDuration;
    newState.reversedWiggle = state.reversedWiggle;
    setState(newState);
  };
  // The Bubble component is structured this way for various CSS reasons:
  // - onAnimationIteration fires if a child's animation iterates
  // - The wiggle animation is shorter than the rise animation
  // - translateY will translate along the rotation the div is facing
  return (
    <div className={componentClasses.bubbleWiggleContainer} style={wiggleContainerStyle}>
      <div
        className={componentClasses.bubbleRiseContainer}
        onAnimationIteration={animationIterationHandler}
        style={riseContainerStyle}
      >
        <div className={componentClasses.bubble} />
      </div>
    </div>
  );
};

// Returns new BubbleState with random attributes and popped = false. Some values should be overridden based on use case.
// If initialPlacement is true, bubbles will be positioned onscreen. If false, y will be shifted to the bottom offscreen.
function createRandomBubbleState(initialPlacement: boolean): BubbleState {
  const minY: number = initialPlacement ? INITIAL_MIN_BUBBLE_Y : MIN_BUBBLE_Y;
  const left: number = MIN_BUBBLE_X + Math.random() * DELTA_BUBBLE_X;
  const top: number = minY + Math.random() * (MAX_BUBBLE_Y - minY);
  const size: number = MIN_BUBBLE_SIZE + Math.random() * DELTA_BUBBLE_SIZE;
  const opacity: number = MIN_BUBBLE_OPACITY + Math.random() * DELTA_BUBBLE_OPACITY;
  const wiggleDuration: number = MIN_BUBBLE_DURATION + Math.random() * DELTA_BUBBLE_DURATION;
  const riseDuration: number = MIN_BUBBLE_DURATION + Math.random() * DELTA_BUBBLE_DURATION;
  const reversedWiggle = Math.random() < 0.5;
  const randomState: BubbleState = {
    vwFromLeft: left,
    vhFromTop: top,
    sizeInVmax: size,
    opacity: opacity,
    wiggleDuration: wiggleDuration,
    riseDuration: riseDuration,
    reversedWiggle: reversedWiggle,
  };
  return randomState;
}
