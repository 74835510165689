import React, { PropsWithChildren } from 'react';

type RenderIfProps = {
  condition: boolean;
};

export const RenderIf: React.FC<PropsWithChildren<RenderIfProps>> = (props) => {
  const { condition = false, children } = props;

  if (condition && !!children) {
    return <>{children}</>;
  }
  return null;
};
