// extracted by mini-css-extract-plugin
export var appear = "footer-module--appear--byE6O";
export var container = "footer-module--container--wjHmS";
export var contentContainer = "footer-module--contentContainer--h+NS8";
export var copyright = "footer-module--copyright--Bg9Rz";
export var copyrightAndLinks = "footer-module--copyrightAndLinks--uIRl6";
export var floating = "footer-module--floating--lLb-3";
export var link = "footer-module--link--svGkA";
export var rounded = "footer-module--rounded--2Q52v";
export var roundedCorners = "footer-module--rounded-corners--as1+J";
export var separator = "footer-module--separator--m+BsU";
export var socialIcon = "footer-module--socialIcon--S6nmx";
export var socialIconButton = "footer-module--socialIconButton--HpSG2";
export var socialIcons = "footer-module--socialIcons--TVgXf";
export var text = "footer-module--text--iKtq-";