import { RenderIf } from '@schedulelab/components/renderIf/renderIf';
import * as navigationBarClasses from 'components/navigationBar/navigationBar.module.scss';
import { useNavigationBar } from 'components/navigationBar/navigationBarHooks';
import { Link } from 'gatsby';
import React from 'react';
import { useToggle } from 'react-use';
import { buildCdnImageUrl } from 'services/url';

export const NavigationBar: React.FC = () => {
  const { pageLinks } = useNavigationBar();
  const [isMobileDropdownOpen, toggleIsMobileDropdownOpen] = useToggle(false);

  return (
    <header className={navigationBarClasses.container}>
      <section className={navigationBarClasses.contentContainer}>
        <Link tabIndex={0} to="/">
          <img
            className={navigationBarClasses.logoImage}
            alt="ScheduleLab Logo"
            src={buildCdnImageUrl('/logo/logotype_white.svg')}
          />
        </Link>
        <section className={navigationBarClasses.desktopPageLinks}>
          {pageLinks.map((pageLink) => (
            <Link key={pageLink.name} className={navigationBarClasses.pageLink} to={pageLink.url}>
              {pageLink.name}
            </Link>
          ))}
        </section>

        <img
          className={navigationBarClasses.mobileDropdownButton}
          onClick={toggleIsMobileDropdownOpen}
          alt="ScheduleLab Logo"
          src={buildCdnImageUrl('/icons/menu_white.svg')}
        />
      </section>
      <RenderIf condition={isMobileDropdownOpen}>
        <section className={navigationBarClasses.mobilePageLinks}>
          {pageLinks.map((pageLink) => (
            <Link
              key={pageLink.name}
              className={navigationBarClasses.pageLink}
              to={pageLink.url}
              onClick={toggleIsMobileDropdownOpen}
            >
              <div>{pageLink.name}</div>
            </Link>
          ))}
        </section>
      </RenderIf>
    </header>
  );
};
