import * as footerClasses from 'components/footer/footer.module.scss';
import { useFooter } from 'components/footer/footerHooks';
import { Link } from 'gatsby';
import React from 'react';

export const Footer: React.FC = () => {
  const { copyrightText, socialButtons } = useFooter();

  return (
    <footer className={footerClasses.container}>
      <section className={footerClasses.contentContainer}>
        <section className={footerClasses.socialIcons}>
          {socialButtons.map((socialButton) => (
            <a
              key={`footer-${socialButton.name}`}
              className={footerClasses.socialIconButton}
              href={socialButton.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className={footerClasses.socialIcon} alt={socialButton.name} src={socialButton.iconUrl} />
            </a>
          ))}
        </section>
        <section className={footerClasses.copyrightAndLinks}>
          <p className={footerClasses.copyright}>{copyrightText}</p>
          <p className={footerClasses.separator}>|</p>
          <Link className={footerClasses.link} to="/privacy">
            Privacy Policy
          </Link>
          <p className={footerClasses.separator}>|</p>
          <Link className={footerClasses.link} to="/terms">
            Terms of Service
          </Link>
          <p className={footerClasses.separator}>|</p>
          <a className={footerClasses.link} href="mailto:support@schedulelab.io">
            Contact Us
          </a>
        </section>
      </section>
    </footer>
  );
};
