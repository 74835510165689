export default class ComponentBounds {
  left?: string;
  top?: string;
  width?: string;
  height?: string;

  // Default setters, to be overridden with React dispatch methods
  setLeft: (value: string) => void = (value: string) => {
    this.left = value;
  };
  setTop: (value: string) => void = (value: string) => {
    this.top = value;
  };
  setWidth: (value: string) => void = (value: string) => {
    this.width = value;
  };
  setHeight: (value: string) => void = (value: string) => {
    this.left = value;
  };

  // Converts dimensions into CSSProperties, for use in the style attribute
  // "fixing" a dimension in this content means setting min & max as well
  public getComputedStyle(fixWidth: boolean, fixHeight: boolean, includePosition: boolean): React.CSSProperties {
    const style: React.CSSProperties = {};
    if (includePosition) {
      if (this.left !== undefined) style.left = this.left;
      if (this.top !== undefined) style.top = this.top;
    }
    if (this.width !== undefined) {
      style.width = this.width;
      if (fixWidth) {
        style.minWidth = this.width;
        style.maxWidth = this.width;
      }
    }
    if (this.height !== undefined) {
      style.height = this.height;
      if (fixHeight) {
        style.minHeight = this.height;
        style.maxHeight = this.height;
      }
    }
    return style;
  }
}
